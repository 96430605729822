import React from 'react'
import { Link } from 'react-router-dom';
import Cat1 from "../Images/Logos/Sidd-II.png";
import Cat2 from "../Images/Logos/Lilac-1.png";
import Cat3 from "../Images/Logos/Imagin-1.png";

const ProductCategory = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-red-800 sm:text-4xl md:mx-auto">
      Product's Category
      </h2>
      <p className="text-base text-gray-700 md:text-lg">
        We deal with variety of products in the field of Radiology and Radiotherapy.
      </p>
    </div>
    <div className="grid gap-8 row-gap-12 lg:grid-cols-3">
      <div className="max-w-md sm:mx-auto sm:text-center">
        <div className="flex items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
        <img src={Cat1} alt="" className="" />
        </div>
        <h6 className="mb-3 text-xl font-bold leading-5">Radiotherapy</h6>
        <p className="mb-3 text-sm text-gray-900">
        Radiation therapy (also called radiotherapy) is a cancer treatment that uses high doses of radiation to kill cancer cells and shrink tumors.
         At low doses, radiation is used in x-rays to see inside your body, as with x-rays of your teeth or broken bones.
        </p>
        <Link
          to="/radiotherapy"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-800 hover:text-blue-900"
        >
          Explore
        </Link>
      </div>
      <div className="max-w-md sm:mx-auto sm:text-center">
        <div className="flex items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
        <img src={Cat2} alt="" className="" />
        </div>
        <h6 className="mb-3 text-xl font-bold leading-5">Radiology</h6>
        <p className="mb-3 text-sm text-gray-900">
        Radiology is a branch of medicine that uses imaging technology to diagnose and treat disease.
         Radiology may be divided into two different areas, diagnostic radiology and interventional radiology. Doctors who specialize in radiology are called radiologists.
        </p>
        <Link
          to="/radiology"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-800 hover:text-blue-900"
        >
          Explore
        </Link>
      </div>      
      <div className="max-w-md sm:mx-auto sm:text-center">
        <div className="flex items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
        <img src={Cat3} alt="" className="" />
        </div>
        <h6 className="mb-3 text-xl font-bold leading-5">Other Equipments</h6>
        <p className="mb-3 text-sm text-gray-900">
       We deal with all other variety of hospital equipments used for different purposes.
        </p>
        <Link
          to="/others"
          aria-label=""
          className="inline-flex items-center font-semibold mt-12 transition-colors duration-200 text-blue-800 hover:text-blue-900"
        >
          Explore
        </Link>
      </div>
    </div>
  </div>
  )
}

export default ProductCategory