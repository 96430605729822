import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Images/Site Logos/navlogotop.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <section className="fixed top-0 left-0 right-0 z-40 bg-gradient-to-r from-blue-900 via-gray-300 to-red-900">
      <div class="px-4 py-6 mx-auto lg:py-8 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between lg:justify-center lg:space-x-16">
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <NavLink
                to="/products"
                aria-label="Products"
                title="Products"
                className={({ isActive }) =>
                    isActive
                      ? "font-bold tracking-wide text-red-800 transition-colors duration-200"
                      : "font-bold tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-800"
                  }>
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                aria-label="services"
                title="services"
                className={({ isActive }) =>
                    isActive
                      ? "font-bold tracking-wide text-red-800 transition-colors duration-200"
                      : "font-bold tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-800"
                  }
              >
                Services
              </NavLink>
            </li>
          </ul>
          <NavLink
            to="/"
            aria-label="GCB"
            title="GCB"
            class="inline-flex items-center"
          >
            <img src={Logo} alt="logo" className="h-16 w-24" />
          </NavLink>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <NavLink
                to="/about"
                aria-label="About us"
                title="About us"
                className={({ isActive }) =>
                    isActive
                      ? "font-bold tracking-wide text-blue-900 transition-colors duration-200"
                      : "font-bold tracking-wide text-gray-900 transition-colors duration-200 hover:text-blue-900"
                  }>
                About us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                aria-label="Contact Us"
                title="Contact Us"
                className={({ isActive }) =>
                isActive
                  ? "font-bold tracking-wide text-blue-900 transition-colors duration-200"
                  : "font-bold tracking-wide text-gray-900 transition-colors duration-200 hover:text-blue-900"
              }>
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-blue-50 focus:bg-blue-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div class="fixed top-0 left-0 w-full z-50">
                <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <NavLink
                        to="/"
                        aria-label="Company"
                        title="Company"
                        class="inline-flex items-center"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <img src={Logo} alt="logo" className="h-16 w-24" />
                      </NavLink>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <NavLink
                          to="/products"
                          aria-label="Products"
                          title="Products"
                          class="font-medium tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-900"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services"
                          aria-label="services"
                          title="services"
                          class="font-medium tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-900"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/about"
                          aria-label="About us"
                          title="About us"
                          class="font-medium tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-900"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          About us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/contact"
                          aria-label="Contact Us"
                          title="Contact Us"
                          class="font-medium tracking-wide text-gray-900 transition-colors duration-200 hover:text-red-900"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
