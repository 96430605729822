import React, { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-[128px]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="mb-2 mb-8 text-4xl text-red-800 font-extrabold text-center leading-none md:pl-2">
            Services We Provide
          </h2>
        </div>
        <div className="max-w-lg space-y-3 sm:mx-auto lg:max-w-xl">
          <div className="flex items-center bg-gray-200 p-2 transition-colors duration-200 border rounded shadow group">
            <div className="mr-2">
              <svg
                className="w-6 h-6 transition-colors duration-200 text-red-800 sm:w-8 sm:h-8"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="text-gray-800 transition-colors duration-200 ">
              Service by Trained professionals
            </span>
          </div>
          <div className="flex items-center bg-gray-200 p-2 transition-colors duration-200 border rounded shadow group">
            <div className="mr-2">
              <svg
                className="w-6 h-6 transition-colors duration-200 text-red-800 sm:w-8 sm:h-8"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="text-gray-800 transition-colors duration-200 ">
              We value your precious time for Installation, Repair and
              Maintenance of our products.
            </span>
          </div>
          <div className="flex items-center bg-gray-200 p-2 transition-colors duration-200 border rounded shadow group">
            <div className="mr-2">
              <svg
                className="w-6 h-6 transition-colors duration-200 text-red-800 sm:w-8 sm:h-8"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="text-gray-800 transition-colors duration-200 ">
              Support for new and running Project
            </span>
          </div>
          <div className="flex items-center bg-gray-200 p-2 transition-colors duration-200 border rounded shadow group">
            <div className="mr-2">
              <svg
                className="w-6 h-6 transition-colors duration-200 text-red-800 sm:w-8 sm:h-8"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="text-gray-800 transition-colors duration-200 ">
              Emergency breakdown support
            </span>
          </div>
          <div className="flex items-center bg-gray-200 p-2 transition-colors duration-200 border rounded shadow group">
            <div className="mr-2">
              <svg
                className="w-6 h-6 transition-colors duration-200 text-red-800 sm:w-8 sm:h-8"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="text-gray-800 transition-colors duration-200 ">
              Quality check, AMC and PMS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
