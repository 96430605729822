import React, { useEffect } from "react";
import ProductCategory from "../Components/ProductCategory";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-[128px]">
      <ProductCategory />
    </div>
  )
}

export default Products