import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import Bhabatron from "../Images/Logos/Bhabhatron.png";
import SidIIIcon from "../Images/Logos/Sidd-II.png";
import Mammography from "../Images/Logos/Lilac-1.png";

const OurProducts = () => {
  useEffect(() => {
   window.scrollTo(0,0)
  }, [])
  
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
      <div className="max-w-3xl mb-10 md:mx-auto sm:text-center lg:max-w-3xl md:mb-12">
        <div>
          <h2 className="max-w-lg mb-6 font-sans text-2xl font-bold leading-none tracking-tight text-blue-800 sm:text-4xl md:mx-auto">
            Our Products
          </h2>
        </div>
        <p className="text-base text-gray-700 md:text-lg">
          We support in consultancy, equipment selections, Quality Assurance,
          machine commissioning, patient immobilization devices, interior
          designing, shielding, Radiation safety and protections, man power
          consulting and training for the Radiotherapy department.
        </p>
      </div>
      <div className="grid row-gap-8 grid-cols-2 lg:grid-cols-3">
        <Link to="/radiotherapy">
          <ProductCard img={Bhabatron} product="Bhabatron 3I" />
        </Link>
        <Link to="/radiotherapy">
          <ProductCard img={SidIIIcon} product="Siddartha II" />
        </Link>
        <Link to="/radiology">
          <ProductCard img={Mammography} product="Lilac" />
        </Link>
      </div>
    </div>
  );
};

export default OurProducts;
