import React from "react";
import HeroImg from "../Images/compressed/Bhabhatron 3.png"

const About = () => {
  return (
    <div className="pt-[128px] px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="flex flex-col lg:flex-row justify-between mt-16 gap-8">
        <div className="w-full lg:w-6/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-blue-900 pb-4">
            About Us
          </h1>
          <p className="font-normal tracking-wider text-base leading-8 text-gray-600 ">
            The journey of 
          <span className="text-red-800 font-bold">"Gauri Chandrika Business International Pvt. Ltd."</span>{" "}
          was started in 2017 with the vision to provide high-quality Medical Equipment, Hospital Supplies, 
          and Surgical Instruments at affordable prices. We provide one spot solution for medical devices and
           medical equipment for hospitals. We are young and dynamic company specializing in the detailed
            product range for Radiotherapy, Radiology, Laboratory, ICU and OT products. We also focus on
            Orthopedic, other hospital equipment and furniture for new or already established hospitals, 
            clinics and diagnostic centers. Our main aim to provide complete solution to the medical institute 
            with best and continuous quality services throughout the time. We are committed to provide{' '}
            <span className="text-red-800 font-bold">“Exceptional Technology and Extraordinary Care” </span>{' '}
            to our end users with the help of our professional human resources.to our end users with the help of our professional human resources.
          </p>
          <p className="font-normal tracking-wider text-base leading-8 text-gray-600 ">
          We support in consultancy, equipment selections, Quality Assurance, machine commissioning, patient immobilization devices,
           interior designing, shielding, Radiation safety and protections, man power consulting and training for the Radiotherapy department. 
          </p>
        </div>
        <div className="w-full lg:w-6/12 ">
          <img
            className="w-full h-full object-cover"
            src={HeroImg}
            alt="Cobalt Therapy"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
