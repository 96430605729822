import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';

import OtherEquipments from './Products/OtherEquipments/SubCategory'

import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import Services from './Pages/Services';
import Products from './Pages/Products';
import Navbar from './Components/Navbar';

import Radiotherapy from './Products/RadioTherapy/SubCategory';
import Radiology from './Products/Radiology/Category';

function App() {
  return (
    <main className="bg-gradient-to-r from-blue-100 via-gray-300 to-red-100">
    <Router>
    <Navbar />
    <Routes>
    <Route path="/" exact element={<Home />} />
    <Route path="/about" exact element={<AboutUs />} />
    <Route path="/contact" exact element={<ContactUs />} />
    <Route path="/services" exact element={<Services />} />
    <Route path="/products" exact element={<Products />} />

    <Route path="/radiotherapy" exact element={<Radiotherapy />} />
    <Route path="/radiology" exact element={<Radiology />} />
    <Route path="/others" exact element={<OtherEquipments />} />
    </Routes>
    <Footer />
    </Router>
    </main>
  );
}

export default App;
