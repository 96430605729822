import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import Infutek from "../../Images/Products/Infutek500.png";
import Infutek405 from "../../Images/Products/Infutek405.png";
import InfutekI9 from "../../Images/Products/InfutekI9.png";
import InfutekNew from "../../Images/Products/Infutek500New.png";
import Item from "../../Components/ViewDetails";

const Tabs = () => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Infusion Pump
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Syringe Pump
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                ICU Monitors
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*Infusion Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={InfutekNew}
                          alt="Infutek500New"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          INFUTEK 500 NEW
                        </h5>
                        <p className="mb-5 text-gray-800">
                          The Simtek INFUTEK 500 volumetric cum Drop counting
                          Infusion Pump has been designed for high levels of
                          safety &amp; performance with simple operation using
                          latest micro controller based system. The Simtek
                          INFUTEK 500 comprehensive range of features makes it
                          ideal for general, critical care &amp; neonatal
                          infusion where accuracy matters.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Big Brightness LED Display
                        <span className="mb-1" />
                        <br />
                        Compact and easy Operation <span className="mb-1" />
                        <br />
                        10 hours of battery backup <span className="mb-1" />
                        <br />
                        Double occlusion sensitivity for sale infusion
                        <span className="mb-1" />
                        <br />
                        9 levels of volume settings
                        <span className="mb-1" />
                        <br />
                        Programmable bolus and KVO function{" "}
                        <span className="mb-1" />
                        <br />
                        Accept all kind of IV set brand
                        <span className="mb-1" />
                        <br />
                        Detachable drip sensor
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*Infusion Product 2*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={Infutek}
                          alt="Infutek500"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          INFUTEK 500
                        </h5>
                        <p className="mb-5 text-gray-800">
                          The Simtek INFUTEK 500 volumetric cum Drop counting
                          Infusion Pump has been designed for high levels of
                          safety &amp; performance with simple operation using
                          latest micro controller based system. The Simtek
                          INFUTEK 500 comprehensive range of features makes it
                          ideal for general, critical care &amp; neonatal
                          infusion where accuracy matters.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        mL/Hr. Setting
                        <span className="mb-1" />
                        <br />
                        Elapsed time display <span className="mb-1" />
                        <br />
                        KVO mode <span className="mb-1" />
                        <br />
                        Drop / min setting
                        <span className="mb-1" />
                        <br />
                        Rechargeable battery
                        <span className="mb-1" />
                        <br />
                        Purge facility <span className="mb-1" />
                        <br />
                        Compatible to all IV sets
                        <span className="mb-1" />
                        <br />
                        Free Flow Protection
                        <span className="mb-1" />
                        <br />
                        Target volume setting
                        <span className="mb-1" />
                        <br />
                        Micro infusions
                        <span className="mb-1" />
                        <br />
                        Bolus
                        <span className="mb-1" />
                        <br />
                        Audio-visual alarms
                        <span className="mb-1" />
                        <br />
                        Large character LCD
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*Syringe Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={InfutekI9}
                          alt="InfutekI9"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          INFUTEK I9
                        </h5>
                        <p className="mb-5 text-gray-800">
                          Simtek‐Infutek i9 series Syringe Infusion Pump is a
                          basic equipment for : Intensive care units (ICU),
                          operating theaters, emergency, anaesthesiology,
                          cardiology, neonatology, paediatrics, oncology and
                          general hospital wards. Ideally suited for
                          administration of anti‐cancer drugs, oxytocics,
                          chemotherapy medication, anaesthesia &amp; life saving
                          drugs. Infutek i9 is indispensable wherever
                          high‐potency drugs are to be precisely delivered at
                          controlled flow rates over an extended period of time.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Compact and light weight design
                        <span className="mb-1" />
                        <br />
                        Precise occlusion pressure settings{" "}
                        <span className="mb-1" />
                        <br />
                        Real time display and more than 80,000 history records
                        <span className="mb-1" />
                        <br />
                        Supports 5, 10, 20, 50(60)ml syringes
                        <span className="mb-1" />
                        <br />
                        Dynamic pressure surveillance (DPS)
                        <span className="mb-1" />
                        <br />
                        Upgradable to PCA (Patient Control Analgesia) mode
                        (optional) <span className="mb-1" />
                        <br />
                        Day / Night Mode
                        <span className="mb-1" />
                        <br />
                        Drug library with more than 100 nos. of commonly used
                        drugs and can be user defined.
                        <span className="mb-1" />
                        <br />
                        Multiple modes of operation to suit clinical demands of
                        infusion
                        <span className="mb-1" />
                        <br />
                        Longer battery backup
                        <span className="mb-1" />
                        <br />
                        Auto purge / Bolus facility
                        <span className="mb-1" />
                        <br />
                        Slick and uniquely engineered impact Resistant Cabinet
                        <span className="mb-1" />
                        <br />
                        KVO Mode
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*Syringe Product 2*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={Infutek405}
                          alt="Infutek405"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          INFUTEK 405
                        </h5>
                        <p className="mb-5 text-gray-800">
                          Simtek-Infutek 405 series syringe driver popularly
                          also known as syringe Infusion Pump is a basic
                          equipment for : Intensive care units (ICU), operating
                          theaters, emergency, anaesthesiology, cardiology,
                          neonatology, paediatrics, oncology and general
                          hospital wards. Ideally suited for administration of
                          anti-cancer drugs, oxytocics, chemotherapy medication,
                          anaesthesia &amp; life saving drugs. Infutek 405 is
                          indispensable wherever high- potency drugs are to be
                          precisely delivered at controlled low rates over an
                          extended period.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Convenience of Front Loading
                        <span className="mb-1" />
                        <br />
                        Utility of Stacking
                        <span className="mb-1" />
                        <br />
                        Drug Library
                        <span className="mb-1" />
                        <br />
                        Allows at a Glance Visualisation of
                        <span className="mb-1" />
                        <br />
                        Drug Names, Volume Infused, Infusion
                        <span className="mb-1" />
                        <br />
                        Time &amp; Flow Rate On LCD / LED Displays
                        <span className="mb-1" />
                        <br />
                        Body Weight Rate Calculation
                        <span className="mb-1" />
                        <br />
                        Bolus with Safety Time Lock
                        <span className="mb-1" />
                        <br />
                        Adjustable Target Volume With Prealarm
                        <span className="mb-1" />
                        <br />
                        Programmable to Accept Any Brand of Syringe
                        <span className="mb-1" />
                        <br />
                        Automatic Rate Calculation with Volume and Time Limit
                        <span className="mb-1" />
                        <br />
                        Upgradable to Double Loop PCA (Optional)
                        <span className="mb-1" />
                        <br />
                        Impact Resistant Strong Cabinet to Protect the Equipment
                        During Accidental Mechanical Impact
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className="my-20">
                    <h1 className="text-center py-20 text-5xl font-extrabold text-red-900 tracking-wider">
                      Comming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SubCategory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="mt-[128px]">
        <nav class="px-5 py-6 rounded-md w-full">
          <ol class="list-reset items-center justify-center flex">
            <li>
              <Link
                to="/"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
              >
                Home
              </Link>
            </li>
            <li>
              <span class="text-gray-500 mx-2">/</span>
            </li>
            <li>
              <Link
                to="/products"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
              >
                Products
              </Link>
            </li>
            <li>
              <span class="text-gray-500 mx-2">/</span>
            </li>
            <li class="font-bold tracking-wide transition-colors duration-200 text-red-900">
              Other Equipments
            </li>
          </ol>
        </nav>
        <Tabs />
      </div>
    </>
  );
};

export default SubCategory;
