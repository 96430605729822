import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import FixDigitalRadio from "../../Images/Products/FixDigitalRadiographySystem.png";
import FlatPanelDetector from "../../Images/Products/FlatPanelDetector.png";
import HighFreqMobile from "../../Images/Products/HighFrequencyMobileSeries.png";
import HighFreqRadio from "../../Images/Products/HighFrequencyRadiographySeries.png";
import HighFreqCArm from "../../Images/Products/HighFrequencyC-Arm.png";
import Item from "../../Components/ViewDetails";
import Mammography from "../../Images/compressed/Mammo Cassate .png";
import MammographyPdf from "../../Pdf/3. Lilac FFDM with Tomosynethesis.pdf";
import MobileDigitalRadio from "../../Images/Products/MobileDigitalRadiographySystem.png";

const Tabs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Mammography
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                X-RAY
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                C-ARM
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                Ultrasound
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={Mammography}
                          alt="Mammography"
                          className="w-full"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Lilac
                        </h5>
                        <p className="mb-5 text-gray-800">
                          LILAC is a state-of-art digital mammography system
                          designed to ease patient’s anxiety, achieve highest
                          quality image with maximum productivity and has an
                          Intuitive workflow for hassle free operation.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Digital Mammography Unit to perform screening,
                        diagnosis, perform tomosynthesis and upgradable to
                        breast biopsy at prone position{" "}
                        <span className="mb-1" />
                        <br />
                        The system consists of an X-Ray Tube Unit with high
                        frequency generator and a mobile workstation{" "}
                        <span className="mb-1" />
                        <br />
                        with microprocessor-controlled touch-based acquisition
                        workstation <span className="mb-1" />
                        <br />
                        The X-ray tube Unit has a C-arm gantry which has
                        Isocentric motion <span className="mb-1" />
                        <br />
                        The C-arm gantry has x-ray tube, compression paddle, and
                        integrated digital detector <span className="mb-1" />
                        <br />
                        The compression paddle is soft and smooth with variable
                        speed <span className="mb-1" />
                        <br />
                        The C-arm has a digital display which displays the
                        parameters of SID, Gantry angle, Field Size, Compression
                        Force and Compression Thickness{" "}
                        <span className="mb-1" />
                        <br />
                        The system has Automatic collimation of the collimator
                        for the field size selected <span className="mb-1" />
                        <br />
                        Integrated field light which switches on and switches
                        off for the field size selected{" "}
                        <span className="mb-1" />
                        <br />
                        Integrated Anatomically programmed Radiography (APR) and
                        in-built Automatic Exposure Control{" "}
                        <span className="mb-1" />
                        <br />
                        (AEC) to ensure optimum dose on the patient{" "}
                        <span className="mb-1" />
                        <br />
                        The exposure is controlled at the acquisition
                        workstation <span className="mb-1" />
                        <br />
                        There are footswitches and machine keypad for the
                        operation of machine <span className="mb-1" />
                        <br />
                        <div className="flex items-center mt-2">
                          <a
                            href={MammographyPdf}
                            target="blank"
                            aria-label=""
                            className="inline-flex items-center font-semibold transition-colors duration-200 text-red-800 hover:text-deep-blue-900"
                          >
                            Get Pdf
                            <svg
                              className="inline-block w-3 ml-2"
                              fill="currentColor"
                              viewBox="0 0 12 12"
                            >
                              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*x-ray Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={FixDigitalRadio}
                          alt="FixDigitalRadiographySystem"
                          className="w-full"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Fix Digital Radiography System
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : DigiFX 15-80
                        </h1>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Ratings : 15/32/40/50/65/80 kW (300-1000 mA)
                        </h1>
                        <p className="mb-5 text-gray-800">
                          DigiFX Series of Digital Radiography systems with a
                          combination of all position movement tables and
                          vertical bucky stand for general and specialized
                          radiography applications. These systems are available
                          with single detector / dual detector options as per
                          user requirements.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Digital Radiography System with Floor to Ceiling Stand /
                        Ceiling Free Stand. <span className="mb-1" />
                        <br />
                        Single Detector System <span className="mb-1" />
                        <br />
                        Flat Panel Detector (FPD) can be fixed in an
                        all-position which is height adjustable and tiltable for
                        flexibility according to patients comfort.{" "}
                        <span className="mb-1" />
                        <br />
                        The tube stand is height adjustable, rotatable and
                        electromagnetically locked. <span className="mb-1" />
                        <br />
                        Manually operated Light Beam Diaphragm to limit X-Ray
                        beam through the shutter. <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*x-ray Product 2*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={MobileDigitalRadio}
                          alt="MobileDigitalRadiographySystem"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Mobile Digital Radiography System
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : MobileX DR 10-32
                        </h1>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Ratings : 10/15/32 kW
                        </h1>
                        <p className="mb-5 text-gray-800">
                          MobileX DR Series is a modern designed Mobile DR which
                          is a portable, monitor assisted DR system which can be
                          easily maneuvered due to its compact design and smooth
                          movements.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        14″ x 17″ wireless FPD. <span className="mb-1" />
                        <br />
                        Customer specific unlimited APR programming.{" "}
                        <span className="mb-1" />
                        <br />
                        Motor assisted noise-free movements.{" "}
                        <span className="mb-1" />
                        <br />
                        Separate structured X-Ray generator and console PC.{" "}
                        <span className="mb-1" />
                        <br />
                        360° rotation of equipment without disturbing its
                        center.
                        <span className="mb-1" />
                        <br />
                        Telescopic swivel tube arm with ±270° rotation.{" "}
                        <span className="mb-1" />
                        <br />
                        Most suitable in a hospital set up where mobile
                        radiographs are performed on patients (pediatric/adult)
                        who are incapable of being moved or are difficult to
                        move. <span className="mb-1" />
                        <br />
                        User-friendly large touch screen operating console.{" "}
                        <span className="mb-1" />
                        <br />
                        Images are displayed on the integrated monitor, as such
                        retakes can be immediately undertaken &amp; additional
                        image views can be chosen. <span className="mb-1" />
                        <br />
                        Examinations are swift with high definition digital
                        images produced within seconds after exposure.{" "}
                        <span className="mb-1" />
                        <br />
                        The immediate reproducibility of images enables trauma
                        assessment teams to act instantly on the clinical
                        information e.g Doctors can interpret fractures
                        immediately in the emergency/trauma room.{" "}
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*x-ray Product 3*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={FlatPanelDetector}
                          alt="FlatPanelDetector"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Flat Panel Detector (DR)
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : Mars1417i , Mars 1717i
                        </h1>
                        <p className="mb-5 text-gray-800">
                          Mars Series DR Retrofit Series  is a suitable retrofit
                          solution to be used for all mobile and fixed X-Ray
                          units. It enhances the entire workflow by delivering
                          diagnostic images instantly. Transfer of the images to
                          an additional workstation is provided to allow users
                          to transfer X-Ray images electronically to remote
                          workstations, image archives and printers. The
                          portable detector is compatible to be used with any
                          make X-Ray machine.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">
                          Components of Fly DR :
                        </span>
                        <br />
                        Wireless FPD (cassette size detector).
                        <span className="mb-1" />
                        <br />
                        Desktop based Image acquisition system for single room
                        use. <span className="mb-1" />
                        <br />
                        Acquisition tablet incase of multi-room application.{" "}
                        <span className="mb-1" />
                        <br />
                      </div>
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        The portable wi-fi detector can be comfortably
                        positioned either in the erect bucky or the table bucky
                        (as per user intended applications).
                        <span className="mb-1" />
                        <br />
                        Suitable to use in emergency / trauma for bedside X-Ray.{" "}
                        <span className="mb-1" />
                        <br />
                        Superior image quality / resolution.{" "}
                        <span className="mb-1" />
                        <br />
                        Portable detector fits standard 14” x 17” / 17” x 17” 
                        bucky tray and its wireless communication enables easy
                        imaging in between table, above table, chest stand and
                        mobile radiography application.{" "}
                        <span className="mb-1" />
                        <br />
                        Auto Exposure Detection (AED) – no interface required
                        with existing X-Ray.
                        <span className="mb-1" />
                        <br />
                        Image storage capacity is more than 10000.{" "}
                        <span className="mb-1" />
                        <br />
                        Image preview less than 5 sec.
                        <span className="mb-1" />
                        <br />
                        High patient throughput. <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*x-ray Product 4*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={HighFreqRadio}
                          alt="HighFrequencyRadiographySeries"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          High Frequency Radiography Series
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : RadX 15-80
                        </h1>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Ratings : 15/32/40/50/65/80 kW
                        </h1>
                        <p className="mb-5 text-gray-800">
                          RadX  15 – 80 KW range of High-Frequency X-Ray
                          machines are specialized models for all general
                          radiography applications. These systems come with
                          various combinations of tube stands and examination
                          tables for various profiles and application needs of
                          specialists.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Anatomical Programme (APR) with film density control in
                        which exposure factors Viz. KV and mAs are automatically
                        selected depending upon the physique of the patient and
                        part of the body to be radiographed.
                        <span className="mb-1" />
                        <br />
                        Inbuilt microcontroller based electronic overload
                        protection device. <span className="mb-1" />
                        <br />
                        Self-diagnostic programme which ensures system check and
                        error display, thereby improving fast fault detection.{" "}
                        <span className="mb-1" />
                        <br />
                        Digital display of parameters. <span className="mb-1" />
                        <br />
                        A dual action hand switch with retractable cord for
                        radiation protection of the operator.
                        <span className="mb-1" />
                        <br />
                        Automatic Exposure Control (AEC) that enables auto
                        selection of radiographic factors, saves time,
                        eliminates retakes, increases diagnostic capability and
                        lowers the radiation dose (optional).{" "}
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>

                    {/*x-ray Product 5*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={HighFreqMobile}
                          alt="HighFrequencyMobileSeries"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          High Frequency Mobile Series (CBM)
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : MobileX 10-32 kW
                        </h1>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Ratings : 10/15/32 kW
                        </h1>
                        <p className="mb-5 text-gray-800">
                          MobileX and MobileXR  mobile x-ray machines are based
                          on mid-range HF X-Ray generation technology. These
                          machines are most suitable for applications where ease
                          of mobility is required like trauma centres and
                          bedside x-rays in hospital wards. These machines are
                          compact, lightweight and can be transported through
                          hospital elevators and corridors.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Easy manoeuvring with Counter Balanced Mobile Stand.
                        <span className="mb-1" />
                        <br />
                        Counter Balanced Mobile (CBM) stand having a telescopic
                        arm for tube assembly movement.{" "}
                        <span className="mb-1" />
                        <br />
                        Ergonomic design with smaller footprints.{" "}
                        <span className="mb-1" />
                        <br />
                        Institutive operation based on Anatomical Programming
                        (APR) with graphic key switches.
                        <span className="mb-1" />
                        <br />
                        Automatic X-Ray Tube Overload Protection.
                        <span className="mb-1" />
                        <br />
                        Feather Touch Micro Controller based control panel.{" "}
                        <span className="mb-1" />
                        <br />
                        Wider Reach of a tube for the patient, especially for
                        Trauma Cases.
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*c-arm Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={HighFreqCArm}
                          alt="HighFrequencyC-Arm"
                          className="w-auto"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          High Frequency C-Arm
                        </h5>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Models : C Series
                        </h1>
                        <h1 className="mb-3 text-2xl font-extrabold leading-none sm:text-xl">
                          Ratings : 3.5KW-6KW
                        </h1>
                        <p className="mb-5 text-gray-800">
                          We are expertise in real-time imaging solutions,
                          technology, innovation and research led to a result of
                          providing cost-effective, modern designed, adaptable
                          and user-friendly C-Arm for Ortho, Uro, Gastro, Spinal
                          Surgeries, anaesthetics, emergency procedures and
                          complicated surgeries.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Single Lever control for C-Arm movement.
                        <span className="mb-1" />
                        <br />
                        Self-diagnostic program to cut service downtime{" "}
                        <span className="mb-1" />
                        <br />
                        Back up circuitry for enhanced performance reliability{" "}
                        <span className="mb-1" />
                        <br />
                        Automatic brightness stabilization (ABS) for hand-free
                        imaging
                        <span className="mb-1" />
                        <br />
                        Thermal safety of tube head
                        <span className="mb-1" />
                        <br />
                        Anatomical programming (APR) Technology{" "}
                        <span className="mb-1" />
                        <br />
                        Automatic dose rate control
                        <span className="mb-1" />
                        <br />
                        Real-time image processing and acquisition
                        <span className="mb-1" />
                        <br />
                        Various memory options as per the application and user
                        requirements
                        <span className="mb-1" />
                        <br />
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div className="my-20">
                    <h1 className="text-center py-20 text-5xl font-extrabold text-red-900 tracking-wider">
                      Comming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Category() {
  return (
    <>
      <div className="mt-[128px]">
        <nav class="px-5 py-6 rounded-md w-full">
          <ol class="list-reset items-center justify-center flex">
            <li>
              <Link
                to="/"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
              >
                Home
              </Link>
            </li>
            <li>
              <span class="text-gray-500 mx-2">/</span>
            </li>
            <li>
              <Link
                to="/products"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
              >
                Products
              </Link>
            </li>
            <li>
              <span class="text-gray-500 mx-2">/</span>
            </li>
            <li class="font-bold tracking-wide transition-colors duration-200 text-red-900">
              Radiology
            </li>
          </ol>
        </nav>
        <Tabs />
      </div>
    </>
  );
}
