import React, { useEffect } from "react";
import About from "../Components/About";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <About />;
};

export default AboutUs;
