import React from 'react'

const ProductCard = ({img , product}) => {
  return (
    <div className="relative overflow-hidden transition duration-300 transform rounded-3xl shadow-3xl hover:scale-105 group hover:shadow-xl mx-6">
          <div className="flex flex-col justify-center p-8 border-0 rounded-3xl shadow-sm">
            <div>
              <div className="flex items-center justify-center mb-4">
                <img src={img} alt="" className="" />
              </div>
              <h6 className="mb-2 font-semibold text-center leading-5">
                {product}
              </h6>
            </div>
          </div>
        </div>
  )
}

export default ProductCard