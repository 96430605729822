import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Item from "../../Components/ViewDetails";

import Bhabatron from "../../Images/compressed/Bhabhatron 3.png";
import BhabatronPdf from "../../Pdf/Bhabhatron 3i technical brochure(1).pdf";
import SidIIIcon from "../../Images/compressed/Siddharth II.png";
import SidIIIconPdf from "../../Pdf/02. Siddharth II Iconic+ (1).pdf";

const Tabs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                RadioTherapy
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Dosimetry
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Immobilization Devices
              </a>
            </li>
            <li className="-mb-px lg:mr-2 last:mr-0 flex-auto text-center border border-black">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-blue-900"
                    : "text-gray-900 bg-white hover:bg-blue-900")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                Simulator
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
                    {/*Product 1*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={SidIIIcon}
                          alt="Bhabatron"
                          className="w-full"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Siddartha II Linear Accelerator
                        </h5>
                        <p className="mb-5 text-gray-800">
                          The ring gantry-based Siddharth II combines advanced
                          technology and simplicity to deliver treatment.
                          Capable of delivering 6MV photons, Siddharth II is an
                          ideal solution for every Radiation Oncology Center
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Magnetron as the RF power source{" "}
                        <span className="mb-1" />
                        <br />
                        Ring Gantry system with bore dimension of 250cm{" "}
                        <span className="mb-1" />
                        <br />
                        Robotic couch with 6-degrees of freedom{" "}
                        <span className="mb-1" />
                        <br />
                        Patented In-Gantry Stereotactic Imaging technology{" "}
                        <span className="mb-1" />
                        <br />
                        Dynamic 30 leaf pairs of MLC <span className="mb-1" />
                        <br />
                        Standing Wave Accelerator guide{" "}
                        <span className="mb-1" />
                        <br />
                        Unsealed Electron gun <span className="mb-1" />
                        <br />
                        Krystal Record and Verification System{" "}
                        <span className="mb-1" />
                        <br />
                        Intuitive workflow with 4-step treatment delivery for
                        all <span className="mb-1" />
                        <br />
                        treatment modalities <span className="mb-1" />
                        <br />
                        Capability to deliver treatment modalities 3D CRT, IGRT,
                        IMRT,VMAT <span className="mb-1" />
                        <br />
                        The system is CE marked and FDA 510(k) cleared{" "}
                        <span className="mb-1" />
                        <br />
                        <div className="flex items-center mt-2">
                          <a
                            href={SidIIIconPdf}
                            target="blank"
                            aria-label=""
                            className="inline-flex items-center font-semibold transition-colors duration-200 text-red-800 hover:text-deep-blue-900"
                          >
                            Get Pdf
                            <svg
                              className="inline-block w-3 ml-2"
                              fill="currentColor"
                              viewBox="0 0 12 12"
                            >
                              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </Item>
                    {/*Product 2*/}
                    <div className="flex flex-col lg:flex-row sm:mx-auto">
                      <div className="relative lg:w-1/3">
                        <img
                          src={Bhabatron}
                          alt="Siddartha"
                          className="w-full"
                        />
                      </div>
                      <div className="flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-2/3">
                        <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                          Bhabatron Cobalt Therapy
                        </h5>
                        <p className="mb-5 text-gray-800">
                          The robustness and simplicity of a cobalt unit
                          combined with image guidance and 6D robotic couch
                          makes Bhabhatron 3i a unique solution for every
                          radiotherapy center. Bhabhatron 3i provides the user
                          with the ease of operation and reliability of a cobalt
                          unit along with several additional features like
                          onboard imaging and 6D robotic couch.
                        </p>
                      </div>
                    </div>
                    <Item title="View Details">
                      <div className="flex flex-col justify-center text-center items-center sm:mx-auto">
                        <span className="font-bold mt-4">SALIENT FEATURES</span>
                        <br />
                        Cobalt 60 of ≤ ɸ2cm
                        <span className="mb-1" />
                        <br />
                        Source Head Capacity: 250 RMM, with Tungsten shielding.
                        <span className="mb-1" />
                        <br />
                        Maximum Dose rate @80 cm: ~ 350 cGy/min
                        <span className="mb-1" />
                        <br />
                        Mulitleaf Collimator on Cobalt Platform
                        <span className="mb-1" />
                        <br />
                        6D Couch with radiolucent carbon fibre couch top
                        <span className="mb-1" />
                        <br />
                        ISO Wedge
                        <span className="mb-1" />
                        <br />
                        Auto Patient Setup
                        <span className="mb-1" />
                        <br />
                        In-Gantry kV Imaging
                        <span className="mb-1" />
                        <br />
                        Krystal R&V Interface
                        <span className="mb-1" />
                        <br />
                        In-built Beam Stopper
                        <span className="mb-1" />
                        <br />
                        Treatment modalities- 3D CRT, IGRT, IMRT
                        <span className="mb-1" />
                        <br />
                        <div className="flex items-center mt-2">
                          <a
                            href={BhabatronPdf}
                            target="blank"
                            aria-label=""
                            className="inline-flex items-center font-semibold transition-colors duration-200 text-red-800 hover:text-deep-blue-900"
                          >
                            Get Pdf
                            <svg
                              className="inline-block w-3 ml-2"
                              fill="currentColor"
                              viewBox="0 0 12 12"
                            >
                              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </Item>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className="my-20">
                    <h1 className="text-center py-20 text-5xl font-extrabold text-red-900 tracking-wider">
                      Comming Soon
                    </h1>
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className="my-20">
                    <p>
                  <span className="font-bold mr-2 text-red-900">Patient Alignment system</span>- Fixed and Movable Laser Lamp- Red
                    and Green <br />
                    <span className="font-bold mr-2 text-red-900">Stereotactic system</span>- SRS and SBRT system with full
                    accessories <br />
                    <span className="font-bold mr-2 text-red-900">Patient set up</span>- CT couch top (carbon fiber)-all CT scan and
                    Linear accelerator compatible. All in one base plate-all
                    type Breast Boards- Supine and prone (carbon fiber and foam
                    both types)-with different inclinations and arm fixed Pelvic
                    boards-Prone and supine with knee and foot supports Head
                    supports-prone and supine Vacuum cushion and accessories
                    Bolus materials-gel, wax etc Water bath Electron cut out
                    system Shielding devices <br />
                    <span className="font-bold mr-2 text-red-900">Thermoplastic Masks</span>- all base plate compatibles for all
                    sites. Different dimensions
                  </p>
                    
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div className="my-20">
                    <h1 className="text-center py-20 text-5xl font-extrabold text-red-900 tracking-wider">
                      Comming Soon
                    </h1>
                  </div>
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <div className="my-20">
                    <h1 className="text-center py-20 text-5xl font-extrabold text-red-900 tracking-wider">
                      Comming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SubCategory = () => {
  return (
    <div className="pt-[128px]">
      <nav class="px-5 py-6 rounded-md w-full">
        <ol class="list-reset items-center justify-center flex">
          <li>
            <Link
              to="/"
              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
            >
              Home
            </Link>
          </li>
          <li>
            <span class="text-gray-500 mx-2">/</span>
          </li>
          <li>
            <Link
              to="/products"
              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-blue-900"
            >
              Products
            </Link>
          </li>
          <li>
            <span class="text-gray-500 mx-2">/</span>
          </li>
          <li class="font-bold tracking-wide transition-colors duration-200 text-red-900">
            RadioTherapy
          </li>
        </ol>
      </nav>
      <Tabs />
    </div>
  );
};

export default SubCategory;
