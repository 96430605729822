import React from "react";


const Contact = () => {


  return (
   
      <div className="px-4 mx-auto pt-10 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">        
        <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="p-5 duration-300 transform bg-white border border-blue-800 border-2 rounded shadow-sm hover:-translate-y-2 flex flex-col justify-center items-center">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-10 h-10 text-blue-800"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Email</h6>
            <p className="text-sm text-gray-900 text-center">
              info@gcbusinessinternational.com
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border border-blue-800 border-2 rounded shadow-sm hover:-translate-y-2 flex flex-col justify-center items-center">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-10 h-10 text-blue-800"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Phone Number</h6>
            <p className="text-sm text-gray-900 text-center">
              +977-9801298075
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border border-blue-800 border-2 rounded shadow-sm hover:-translate-y-2 flex flex-col justify-center items-center">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-10 h-10 text-blue-800"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Address</h6>
            <p className="text-sm text-gray-900 text-center">
              Kathmandu, Nepal
            </p>
          </div>          
        </div>
      </div>
  );
};

export default Contact;
