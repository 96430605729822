import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import Contact from "../Components/Contact";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  let Navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submit = () => {
    
    const data = {};
    data.name = name;
    data.email = email;
    data.phone = phone;
    data.message = message;
    console.log(data);

    axios
      .post("https://backendartticon2022.gcbusinessinternational.com/master/sendmail", data)
      .then((response) => {
        alert(response.data.message);   
      });
           
      Navigate("/");
  };
  return (
    <>
      <div className="pt-[128px]">
        <div class="max-w-screen-xl my-8 px-8 md:px-12  lg:px-16 xl:px-32 py-16 mx-auto text-gray-900 ">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-blue-900 sm:text-4xl md:mx-auto">
              Contact Us
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Feel free to contact us for any queries or information.
            </p>
          </div>
          <form onSubmit={submit} class="">
            <div>
              <span class="uppercase text-sm text-gray-900 font-bold">
                Full Name
              </span>
              <input
                class="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>

            <div class="mt-8">
              <span class="uppercase text-sm text-gray-900 font-bold">
                Email
              </span>
              <input
                class="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>

            <div class="mt-8">
              <span class="uppercase text-sm text-gray-900 font-bold">
                Phone Number
              </span>
              <input
                class="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="number"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter Your Number"
                required
              />
            </div>

            <div class="mt-8">
              <span class="uppercase text-sm text-gray-900 font-bold">
                Message
              </span>
              <textarea
                placeholder="Enter Your Message"
                onChange={(e) => setMessage(e.target.value)}
                required
                class="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              ></textarea>
            </div>

            <div class="mt-8">
              <input
                type="submit"
                value="send"
                class="uppercase text-sm font-bold tracking-wide bg-red-400 hover:bg-red-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
              />
            </div>
          </form>
        </div>
        <Contact />
      </div>
    </>
  );
};

export default ContactUs;
