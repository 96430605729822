import React, { useEffect } from "react";
import Hero from "../Components/Hero";
import IconSlider from "../Components/IconSlider";
import OurProducts from "../Components/OurProducts";
import Stats from "../Components/Stats";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <OurProducts />
      {/* <Stats />
      <IconSlider /> */}
    </>
  );
};

export default Home;
