import React from "react";
import { Link } from "react-router-dom";

import Image1 from "../Images/compressed/Siddharth II.png"

const Hero = () => {
  return (
    <div className="pt-[128px]">
      <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="flex flex-col items-end w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-2xl font-bold text-red-800 sm:text-3xl sm:leading-none sm:items-center">
                Exceptional Technology
                <br className="hidden md:block" />
                <span className="inline-block text-blue-800">
                  Extra Ordinary Care
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
              <span className="text-red-800 font-bold">"Gauri Chandrika Business International Pvt. Ltd."</span>{" "}
              is completely a different platform for all types of medical solutions in Nepal. We are dedicated to Radiation Care and other medical solutions.
               We deal with initial set up of Radiation Facility in Radiation Oncology, Nuclear Medicine and Diagnostic Radiology and other medical departments. 

              </p>
            </div>
            <div className="flex flex-col items-center md:flex-row">
              <Link
                to="/about"
                className="inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-red-600 hover:bg-red-900 hover:text-black  focus:shadow-outline focus:outline-none"
              >
                About Us
              </Link>
              <Link
                to="/contact"
                aria-label=""
                className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-blue-800"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center left-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:pt-14  lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
          <img
            className="h-full object-cover rounded shadow-lg lg:rounded-none lg:shadow-none "
            src={Image1}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
